<template>
  <div>
    <!-- <form name="form1" method="post" action="./Search.aspx" id="form1"> -->
    <div>
      <input
        type="hidden"
        name="__VIEWSTATE"
        id="__VIEWSTATE"
        value="/wEPDwULLTE5MTY1NTI3NjcPZBYCAgMPZBYCAgcPFgIeBFRleHQFrAE8aDMgY2xhc3M9ImYyMCBtdDIwIiBzdHlsZT0iZm9udC13ZWlnaHQ6IG5vcm1hbCI+6L+Q5Y2V5Y+377yaMTI8L2gzPjx1bCBjbGFzcz0idGMgZjE4IG10MjAgZzYiPjxsaT7ov5DljZXlj7fkuI3lrZjlnKjvvIzor7fnoa7orqTmgqjovpPlhaXnmoTorqLljZXlj7fmmK/lkKbmraPnoa48L2xpPjwvdWw+ZGS/FgEE0k6EuI1ZwFgQZeZq2QpNh3qtNAz0lTC/a6zHKA=="
      />
    </div>

    <div>
      <input
        type="hidden"
        name="__VIEWSTATEGENERATOR"
        id="__VIEWSTATEGENERATOR"
        value="BBBC20B8"
      />
      <input
        type="hidden"
        name="__EVENTVALIDATION"
        id="__EVENTVALIDATION"
        value="/wEdAAOzevGdQUGY9IxpE4is22wy/nxX1llchIv/O/FMEafKsI7U3Vc0WZ+wxclqyPFfzmNtC2VwCVxrAOwUgDUmt+r96m652w+kRugCgViSF1atZw=="
      />
    </div>
    <div class="wrap" @click="hidenAsideRight">
      <div class="">
        <div class="header">
          <div class="top tr hidden-phone">
            <div class="w1180">
              <div class="fl">
                您好，欢迎来到三象速递官网
              </div>
              <div class="fr">
                www.sxexpress.com.au
              </div>
              <div class="cl"></div>
            </div>
          </div>

          <div class="w1180 clearfix">
            <h2 class="fl pt10 pb10 logo">
              <a href="/"> <img src="@/assets/images/logo.png"/></a>
            </h2>
            <div class="fr pt10 pb10 phone-nav hidden-pc">
              <i class="el-icon-s-unfold" @click.stop="changeMenu"></i>
            </div>
            <div class="fr mt20 ml30 hidden-phone" style="position: relative;">
              <ul>
                <li class="fr ml20">
                  <a
                    href="javascript:;"
                    onmouseover="document.getElementById('weixinDIV').style.display = 'block';"
                    onmouseout="document.getElementById('weixinDIV').style.display = 'none';"
                  >
                    <img
                      src="@/assets/images/top-wx.png"
                      alt="与微信客服交流"
                    />
                  </a>
                </li>
                <li class="fr">
                  <a
                    href="http://wpa.qq.com/msgrd?v=3&amp;uin=3376684454&amp;site=qq&amp;menu=yes"
                  >
                    <img src="@/assets/images/top-qq.png" alt="与QQ客服交流" />
                  </a>
                </li>
              </ul>
              <div
                id="weixinDIV"
                onmouseover="this.style.display = 'block';"
                onmouseout="this.style.display = 'none';"
                style="position: absolute; right: 0px; top: 45px; display: none; z-index: 10000;"
              >
                <img src="@/assets/images/wx.jpg" style="width: 140px;" />
              </div>
            </div>
            <div class="nav fr hidden-phone">
              <ul>
                <li><a href="/">首页</a></li>
                <!-- <li><a href="news.aspx">活动公告</a></li> -->
                <li><a href="/service">特色服务</a></li>
                <li><a href="/about">关于三象</a></li>
                <li><a href="/contact">联系三象</a></li>
              </ul>
            </div>
          </div>
        </div>

        <!--START: Banner-->

        <!--START: Content -->

        <div class="inside">
          <div class="inside_banner user_banner">
            <div class="title f14 w1180 fw">
              <div style="margin-left: 25px">
                <span class="line"></span>
                <h2>运单查询</h2>
              </div>
            </div>
          </div>
          <div class="w1180 clearfix fw">
            <div
              class="f16"
              style="line-height: 34px; background: #fff; padding: 20px; margin-bottom: 30px"
            >
              <div class="wrap w1180 clearfix">
                <div class="left trackLeft">
                  <div class="subtitle f16 hidden-phone">运单查询</div>
                  <div class="track">
                    <textarea
                      name="txtbill"
                      rows="20"
                      cols="20"
                      id="txtbill"
                      placeholder="请输入要查询的单号，多个单号请用Enter(回车)键隔开"
                      style="height:80px;"
                    >
12</textarea
                    >
                    <input
                      type="submit"
                      name="btnSearch"
                      value="查 询"
                      id="btnSearch"
                      class="btn"
                    />
                  </div>
                </div>
                <div class="trackRight">
                  <h4 class="bbd mb20">订单跟踪详情</h4>
                  <div>{{ result }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--END: Content -->

        <div class="foot">
          <div class="copyright w1180 tc">
            <p>
              Copyright © 2017-2020 三象速递
              &nbsp;&nbsp;&nbsp;&nbsp;联系电话：+61 1300965539
              &nbsp;&nbsp;&nbsp;&nbsp;地址：Po Box 6635, Point Cook, VIC
              3030&nbsp;&nbsp;&nbsp;&nbsp;<a
                href="http://www.kuaidi100.com/"
                target="_blank"
                >快递查询</a
              >
            </p>
          </div>
        </div>

        <!-- 代码部分end -->
      </div>
    </div>

    <!-- aside right -->
    <aside
      class="ui-aside asideRight w23"
      :style="
        showAsideRight
          ? 'top: 0px; bottom: 0px; right: -1px; width: 120px;'
          : 'top: 0px; bottom: 0px; right: -4rem; width: 0'
      "
    >
      <ul>
        <li><a href="/">首页</a></li>
        <!-- <li><a href="news.aspx">活动公告</a></li> -->
        <li><a href="/service">特色服务</a></li>
        <li><a href="/about">关于三象</a></li>
        <li><a href="/contact">联系三象</a></li>
      </ul>
    </aside>
    <!-- </form> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      result: {},
      showAsideRight: false
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const { no } = this.$route.query;
      // const url = `/oms/api/tracking/ewe/${no}`;
      const url = "/oms/api/tracking/ewepost";
      // this.sending = true;
      this.$request(url, {
        method: "POST",
        data: {
          IsDetailed: true,
          querystring: no
        }
      })
        .then(res => {
          this.result = res;
        })
        .catch(() => {
          // this.sending = false;
        });
    },
    changeMenu() {
      //移动端右侧滑出
      this.showAsideRight = true;
    },
    hidenAsideRight() {
      this.showAsideRight = false;
    }
  }
};
</script>
