var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_c('div',{staticClass:"wrap",on:{"click":_vm.hidenAsideRight}},[_c('div',{},[_c('div',{staticClass:"header"},[_vm._m(2),_c('div',{staticClass:"w1180 clearfix"},[_vm._m(3),_c('div',{staticClass:"fr pt10 pb10 phone-nav hidden-pc"},[_c('i',{staticClass:"el-icon-s-unfold",on:{"click":function($event){$event.stopPropagation();return _vm.changeMenu.apply(null, arguments)}}})]),_vm._m(4),_vm._m(5)])]),_c('div',{staticClass:"inside"},[_vm._m(6),_c('div',{staticClass:"w1180 clearfix fw"},[_c('div',{staticClass:"f16",staticStyle:{"line-height":"34px","background":"#fff","padding":"20px","margin-bottom":"30px"}},[_c('div',{staticClass:"wrap w1180 clearfix"},[_vm._m(7),_c('div',{staticClass:"trackRight"},[_c('h4',{staticClass:"bbd mb20"},[_vm._v("订单跟踪详情")]),_c('div',[_vm._v(_vm._s(_vm.result))])])])])])]),_vm._m(8)])]),_c('aside',{staticClass:"ui-aside asideRight w23",style:(_vm.showAsideRight
          ? 'top: 0px; bottom: 0px; right: -1px; width: 120px;'
          : 'top: 0px; bottom: 0px; right: -4rem; width: 0')},[_vm._m(9)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('input',{attrs:{"type":"hidden","name":"__VIEWSTATE","id":"__VIEWSTATE","value":"/wEPDwULLTE5MTY1NTI3NjcPZBYCAgMPZBYCAgcPFgIeBFRleHQFrAE8aDMgY2xhc3M9ImYyMCBtdDIwIiBzdHlsZT0iZm9udC13ZWlnaHQ6IG5vcm1hbCI+6L+Q5Y2V5Y+377yaMTI8L2gzPjx1bCBjbGFzcz0idGMgZjE4IG10MjAgZzYiPjxsaT7ov5DljZXlj7fkuI3lrZjlnKjvvIzor7fnoa7orqTmgqjovpPlhaXnmoTorqLljZXlj7fmmK/lkKbmraPnoa48L2xpPjwvdWw+ZGS/FgEE0k6EuI1ZwFgQZeZq2QpNh3qtNAz0lTC/a6zHKA=="}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('input',{attrs:{"type":"hidden","name":"__VIEWSTATEGENERATOR","id":"__VIEWSTATEGENERATOR","value":"BBBC20B8"}}),_c('input',{attrs:{"type":"hidden","name":"__EVENTVALIDATION","id":"__EVENTVALIDATION","value":"/wEdAAOzevGdQUGY9IxpE4is22wy/nxX1llchIv/O/FMEafKsI7U3Vc0WZ+wxclqyPFfzmNtC2VwCVxrAOwUgDUmt+r96m652w+kRugCgViSF1atZw=="}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top tr hidden-phone"},[_c('div',{staticClass:"w1180"},[_c('div',{staticClass:"fl"},[_vm._v(" 您好，欢迎来到三象速递官网 ")]),_c('div',{staticClass:"fr"},[_vm._v(" www.sxexpress.com.au ")]),_c('div',{staticClass:"cl"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"fl pt10 pb10 logo"},[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fr mt20 ml30 hidden-phone",staticStyle:{"position":"relative"}},[_c('ul',[_c('li',{staticClass:"fr ml20"},[_c('a',{attrs:{"href":"javascript:;","onmouseover":"document.getElementById('weixinDIV').style.display = 'block';","onmouseout":"document.getElementById('weixinDIV').style.display = 'none';"}},[_c('img',{attrs:{"src":require("@/assets/images/top-wx.png"),"alt":"与微信客服交流"}})])]),_c('li',{staticClass:"fr"},[_c('a',{attrs:{"href":"http://wpa.qq.com/msgrd?v=3&uin=3376684454&site=qq&menu=yes"}},[_c('img',{attrs:{"src":require("@/assets/images/top-qq.png"),"alt":"与QQ客服交流"}})])])]),_c('div',{staticStyle:{"position":"absolute","right":"0px","top":"45px","display":"none","z-index":"10000"},attrs:{"id":"weixinDIV","onmouseover":"this.style.display = 'block';","onmouseout":"this.style.display = 'none';"}},[_c('img',{staticStyle:{"width":"140px"},attrs:{"src":require("@/assets/images/wx.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav fr hidden-phone"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"/"}},[_vm._v("首页")])]),_c('li',[_c('a',{attrs:{"href":"/service"}},[_vm._v("特色服务")])]),_c('li',[_c('a',{attrs:{"href":"/about"}},[_vm._v("关于三象")])]),_c('li',[_c('a',{attrs:{"href":"/contact"}},[_vm._v("联系三象")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inside_banner user_banner"},[_c('div',{staticClass:"title f14 w1180 fw"},[_c('div',{staticStyle:{"margin-left":"25px"}},[_c('span',{staticClass:"line"}),_c('h2',[_vm._v("运单查询")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left trackLeft"},[_c('div',{staticClass:"subtitle f16 hidden-phone"},[_vm._v("运单查询")]),_c('div',{staticClass:"track"},[_c('textarea',{staticStyle:{"height":"80px"},attrs:{"name":"txtbill","rows":"20","cols":"20","id":"txtbill","placeholder":"请输入要查询的单号，多个单号请用Enter(回车)键隔开"}},[_vm._v("12")]),_c('input',{staticClass:"btn",attrs:{"type":"submit","name":"btnSearch","value":"查 询","id":"btnSearch"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot"},[_c('div',{staticClass:"copyright w1180 tc"},[_c('p',[_vm._v(" Copyright © 2017-2020 三象速递     联系电话：+61 1300965539     地址：Po Box 6635, Point Cook, VIC 3030    "),_c('a',{attrs:{"href":"http://www.kuaidi100.com/","target":"_blank"}},[_vm._v("快递查询")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('a',{attrs:{"href":"/"}},[_vm._v("首页")])]),_c('li',[_c('a',{attrs:{"href":"/service"}},[_vm._v("特色服务")])]),_c('li',[_c('a',{attrs:{"href":"/about"}},[_vm._v("关于三象")])]),_c('li',[_c('a',{attrs:{"href":"/contact"}},[_vm._v("联系三象")])])])
}]

export { render, staticRenderFns }